<template>
  <div id="app">
    <div class="homeSystem">
      <div :class="containerClass" @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle" />
        <transition name="layout-sidebar">
          <div
            :class="sidebarClass"
            @click="onSidebarClick"
            v-show="isSidebarVisible()"
          >
            <div class="layout-logo">
              <router-link to="/appSystem">
                <img
                  alt="Logo"
                  src="../public/assets/layout/images/user2.png"
                  width="70"
                />
              </router-link>
            </div>
            <div class="p-text-center">
              <h5 style="color: #fff">{{ usuario[0].nome }}</h5>
              <hr />
              <h5 style="color: #fff">{{ usuario[0].tipo }}</h5>
            </div>
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
          </div>
        </transition>
        <div class="layout-main">
          <router-view />
        </div>
        <AppFooter />
      </div>
    </div>
    <!--MODAL ALTERAR SENHA-->
    <Dialog
      v-model:visible="usuarioAlterarSenhaDialog"
      :style="{ width: '400px' }"
      header="Alterar Senha"
      :modal="true"
      class="p-fluid"
    >
      <br />
      <!--  CADASTRO DE USUARIO -->
      <div class="p-shadow-4" style="padding: 30px">
        <div class="p-fluid p-formgrid p-grid">
          <div v-if="msgError">
            <Message severity="error">{{ msgError }}</Message>
          </div>
          <div class="p-field p-col-12">
            <label for="senhaAtual">Senha Atual</label>
            <Password
              v-model="altSenha.senhaAntiga"
              :feedback="false"
              toggleMask="true"
            ></Password>
          </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <label for="nome">Nova Senha</label>
            <Password
              v-model="altSenha.novaSenha1"
              :feedback="false"
              toggleMask
            ></Password>
          </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <label for="nome">Confirmar Nova Senha</label>
            <Password
              v-model="altSenha.novaSenha2"
              :feedback="false"
              toggleMask="true"
            ></Password>
          </div>
        </div>
      </div>
      <br />
      <template #footer>
        <Button
          label="Salvar"
          class="p-button-raised"
          icon="pi pi-check"
          @click="alterarSenha"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-raised p-button-text"
          @click="usuarioAlterarSenhaDialog = false"
        />
      </template>
    </Dialog>
    <!--FIM MODAL MODAL ALTERAR SENHA-->
  </div>
</template>

<script>
import AppTopBar from "./components/gerais/AppTopbar.vue";
import AppMenu from "./components/gerais/AppMenu.vue";
import AppFooter from "./components/gerais/AppFooter.vue";

import AuthService from "@/service/Auth/AuthService";
import UsersService from "@/service/Users/UsersService";

export default {
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppFooter: AppFooter,
  },
  data() {
    return {
      layoutMode: "static",
      layoutColorMode: "dark",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      msgError: null,
      usuario: [],
      usuarioAlterarSenhaDialog: false,
      altSenha: {
        senhaAntiga: null,
        novaSenha1: null,
        novaSenha2: null,
      },
      menu: [
        {
          label: "Dashboard",
          icon: "pi pi-fw pi-home",
          to: "/appSystem",
        },
        {
          label: "Visitantes",
          icon: "pi pi-fw pi-clone",
          items: [
            {
              label: "Cadastrar Visitantes",
              icon: "pi pi-fw pi-user-plus",
              to: "/cadastrarVisitante",
            },
            {
              label: "Entrada de Visitantes",
              icon: "pi pi-fw pi-sign-in",
              to: "/entradaVisitante",
            },
            {
              label: "Saída de Visitantes",
              icon: "pi pi-fw pi-sign-out",
              to: "/saidaVisitante",
            },
          ],
        },
        {
          label: "Gestão de Acesso",
          icon: "pi pi-fw pi-clone",
          visible: () => this.isUserAdmin(),
          items: [
            {
              label: "Gestão de Crachas",
              icon: "pi pi-fw pi-id-card",
              to: "/cracha",
            },
            {
              label: "Gestão de Equipamentos",
              icon: "pi pi-fw pi-clone",
              to: "/equipamentos",
            },
          ],
        },
        {
          label: "Controle de Acesso",
          icon: "pi pi-fw pi-clone",
          visible: () => this.isUserAdmin(),
          items: [
            {
              label: "Controle de Acesso Diário",
              icon: "pi pi-fw pi-clock",
              to: "/pontoDiario",
            },
            {
              label: "Controle de Acesso Mensal",
              icon: "pi pi-fw pi-calendar",
              to: "/pontoMensal",
            },
          ],
        },
        {
          label: "Pessoas e Servidores",
          icon: "pi pi-fw pi-clone",
          visible: () => this.isUserAdmin(),
          items: [
            {
              label: "Gestao de Pessoas",
              icon: "pi pi-fw pi-users",
              to: "/pessoa",
            },
            {
              label: "Gestão de Categorias",
              icon: "pi pi-fw pi-th-large",
              to: "/categoria",
            },
          ],
        },

        {
          label: "Institucional",
          icon: "pi pi-fw pi-bookmark",
          visible: () => this.isUserAdmin(),
          items: [
            {
              label: "Cargos e Funções",
              icon: "pi pi-fw pi-list",
              to: "/funcaoAndCargo",
            },
            {
              label: "Setores",
              icon: "pi pi-fw pi-sitemap",
              to: "/setor",
            },
          ],
        },
        {
          label: "Usuário",
          icon: "pi pi-fw pi-clone",
          items: [
            {
              label: "Gerenciamento de Usuário",
              icon: "pi pi-fw pi-users",
              visible: () => this.isUserAdmin(),
              to: "/usuario",
            },
            {
              label: "Alterar Senha",
              icon: "pi pi-fw pi-lock",
              visible: () => this.isUserTec(),
              command: () => {
                this.usuarioAlterarSenhaDialog = true;
              },
            },
          ],
        },
        {
          label: "Sair",
          icon: "pi pi-fw pi-sign-out",
          command: () => {
            this.logout();
          },
        },
      ],
    };
  },
  authService: null,
  usersService: null,
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  created() {
    this.authService = new AuthService();
    if (sessionStorage.getItem("token")) {
      this.$router.push("/appSystem");
      this.configs();
      this.usersService = new UsersService();
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }
          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    onLayoutColorChange(layoutColorMode) {
      this.layoutColorMode = layoutColorMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
        else return true;
      } else {
        return true;
      }
    },
    configs() {
      let token = sessionStorage.getItem("token");
      token = JSON.parse(token);
      token = [{ nome: token.nome, tipo: token.tipo, id: token.id }];
      this.usuario = token;
    },
    logout() {
      if (this.authService.verificarTokenExpirado() === true) {
        this.authService.limparAccessToken();
        this.$router.push("/");
      } else {
        this.authService.logout().then((response) => {
          if (response.status == 204) {
            this.authService.limparAccessToken();
            this.$router.push("/");
          }
        });
      }
    },
    isUserAdmin() {
      if (this.usuario[0].tipo === "Administrador") {
        return true;
      } else {
        return false;
      }
    },
    isUserTec() {
      if (this.usuario[0].tipo === "Operador") {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$appState.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.ripple === false,
        },
      ];
    },
    sidebarClass() {
      return [
        "layout-sidebar",
        {
          "layout-sidebar-dark": this.layoutColorMode === "dark",
          "layout-sidebar-light": this.layoutColorMode === "light",
        },
      ];
    },
  },
  /*
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },*/
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
